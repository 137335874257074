<template>
    <div>
        <a-drawer title="电话类型" :width="540" :visible="show" :body-style="{ paddingBottom: '80px' }" @close="addClose">
            <div class="drawer-content" style="padding: 16px">
                电话类型
                <a-divider></a-divider>
                <!-- tags -->
                <span v-for="item,index in typeList" :key="item.id">
                <a-input
                    v-if="item.show==true"
                    ref="input"
                    v-focus
                    type="text"
                    size="small"
                    :style="{ width: '78px','padding-bottom':'3px','margin-right':'7px' }"
                    v-model="item.name"
                    @blur="editType(item)"
                    @keyup.enter="editType(item)"
                />
                <a-tag
                    v-else-if="item.show==false" closable @close.prevent="delType(item.id)"
                    @click="editInput(item,index)" >
                    {{item.name}}
                </a-tag>
                </span>
                <!-- addTag -->
                <span>
                <a-input
                    v-if="inputVisible"
                    ref="addInput"
                    type="text"
                    size="small"
                    :style="{ width: '78px','padding-bottom':'3px'}"
                    :value="typeForm.name"
                    @change="handleInputChange"
                    @blur="handleInputConfirm"
                    @keyup.enter="handleInputConfirm"
                />
                <a-tag v-else style="background: #fff; borderStyle: dashed;" @click="addShow">
                    <a-icon type="plus" /> 新增类型
                </a-tag>
                </span>
            </div>
            <div class="drawer-footer">
                <a-button :style="{ marginRight: '8px' }" @click="addClose">
                关闭
                </a-button>
            </div>
        </a-drawer>
    </div>
</template>

<script>
import {getPhontTypeList, addPhontType, delPhontType,updatePhontType} from "@/api/operation/suggestion"
export default {
    props: {
		show: {
			type: Boolean,
			default: false,
		},
	},
    data() {
        return {
            typeForm: {
                name: ''
            },
            typeList: [],
            inputVisible: false
        }
    },
    created() {
        this.getData()
    },
    methods: {
        async getData() {
			this.typeList = []
			const res = await getPhontTypeList();
			const arr = res.data;
			arr.forEach(ele => {
				ele.show = false;
			})
			this.typeList = arr;
            this.$emit("refresh");
		},
        //输入
        editInput(obj, i) {
            this.$set(this.typeList[i],'show',true)
			this.$forceUpdate()
        },
        //新增
        addShow(){
			this.inputVisible = true;
			this.$nextTick(function() {
				this.$refs.addInput.focus();
			});
		},
        //编辑
        async editType(item){
			let res = await updatePhontType({
				id: item.id,
				name: item.name
			})
			if(res.code === 200){
				item.show = false
				this.$forceUpdate()
				this.$message.success(res.msg)
			}else{
				this.$message.error(res.msg)
			}
		},
        handleInputChange(e) {
            this.typeForm.name = e.target.value;
        },
        //新增和编辑确认
        async handleInputConfirm() {
			if(this.typeForm.name === ''){
				this.inputVisible = false;
				return
			}
			let res = await addPhontType({
				name:this.typeForm.name,
			})
			if(res.code === 200){
				this.$message.success(res.msg);
				this.inputVisible = false;
				this.typeForm.name = '';
				this.getData()
			}else{
				this.$message.error(res.msg);
			}
		},
        //删除
        delType(id){
			this.$confirm({
				title: "是否删除",
				icon:'delete',
				onOk:async()=>{
					let res = await delPhontType({conveniencePhoneTypeId: id})
					if(res.code=== 200){
						this.$message.success(res.msg);
						this.getData()
					}else{
						this.$message.error(res.msg);
					}
				},
			})
		},
        addClose() {
            this.$emit("addClose");
        },
    },
    watch: {

	},
}
</script>

<style lang="less">

</style>