<template>
    <div>
        <div class="cardTitle">
            <a-space size="large">
                <span>便民电话</span>
            </a-space>
        </div>
        <div class="search-box">
            <a-row>
                <a-col :span="20">
                <a-space size="large">
                    <a-input v-model="form.name" style="width: 200px" placeholder="联系名称"></a-input>
                    <a-input v-model="form.tel" style="width: 200px" placeholder="联系电话"></a-input>
                    <a-button type="primary" @click='getData'>查 询</a-button>
                    <a-button @click='reset'>重 置</a-button>
                </a-space>
                </a-col>
            </a-row>
        </div>
        <a-button style="margin: 10px" class="add-btn" @click="detailShow = true;mode = 1">新增电话</a-button>
        <a-button style="margin: 10px" @click="addType" type="primary" >新增电话类型</a-button>
        <a-button style="margin: 10px" @click="check" type="primary" ghost>定时检查</a-button>
        <div class="main">
            <a-table :columns="columns" :data-source="tableData" 
                :pagination="pagination" @change="handlerChange" :scroll="{ x: 1700 }" 
                :row-selection="{
                    selectedRowKeys: selectedRowKeys,
                    onChange: selectionChoosed,
                }"
                :row-key="
                (record, index) => {
                return record.id;
                }">
                <template slot="action" slot-scope="text,record">
                    <span><a @click=edit(record)>编辑</a></span>
                    <span><a style="margin-left: 8px;color:red" @click=del(record)>删除</a></span>
                </template>
            </a-table>
            <div class="action">
                <a-dropdown :disabled="!hasSelected">
                    <a-menu slot="overlay" @click="handleMenuClick">
                    <a-menu-item key="del"> 批量删除 </a-menu-item>
                    </a-menu>
                    <a-button> 批量操作 <a-icon type="down" /> </a-button>
                </a-dropdown>
                <span style="margin-left: 8px">
                    <template v-if="hasSelected">
                    {{ `已选择 ${selectedRowKeys.length} 条` }}
                    </template>
                </span>
            </div>
        </div>
        <a-drawer :title="mode==1?'新增电话':'编辑电话'" :width="720" :visible="detailShow" :body-style="{ paddingBottom: '60px' }" @close="detailClose">
            <div class="drawer-content">
                <span>基本信息</span>
                <a-divider></a-divider>
                <a-row>
                    <a-form-model :model="addForm" :rules="rules">
                    <a-col :span="12">
                        <a-form-model-item label="联系名称" prop="name">
                            <a-input style="width: 240px" v-model="addForm.name"></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="联系电话" prop="tel">
                            <a-input style="width: 240px" v-model="addForm.tel"></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="类型" prop="type">
                            <a-select style="width: 240px" v-model="addForm.type">
                                <a-select-option v-for="item in typeList" :value="item.id" :key="item.id">
                                    {{item.name}}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="状态" prop="status">
                            <a-select style="width: 240px" v-model="addForm.status"> 
                                <a-select-option :value="1">正常</a-select-option>
                                <a-select-option :value="2">有误</a-select-option>
                                <a-select-option :value="3">空号</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="权重" prop="weight">
                            <a-input-number style="width: 120px" v-model="addForm.weight" :min="0"></a-input-number>
                        </a-form-model-item>
                    </a-col>
                    </a-form-model>
                </a-row>
            </div>
            <div class="drawer-footer" style="float: left">
                <a-button @click="addConfirm" type="primary" :style="{ marginRight: '8px' }">
                    确认
                </a-button>
                <a-button @click="detailClose"> 关闭 </a-button>
            </div>
        </a-drawer>
        <a-drawer title="定时检查" :width="720" :visible="checkShow" :body-style="{ paddingBottom: '60px' }" @close="checkClose">
            <div class="drawer-content">
                <span>基本信息</span>
                <a-divider></a-divider>
                <a-row>
                    <a-form-model>
                        <a-col :span="20">
                            <a-form-model-item label="提醒间隔">
                                <a-select v-model="checkForm.reminderInterval">
                                    <a-select-option :value="1">一个月</a-select-option>
                                    <a-select-option :value="2">半年</a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="20">
                            <a-form-model-item label="提醒内容">
                                <a-textarea v-model="checkForm.reminderContent"></a-textarea>
                            </a-form-model-item>
                        </a-col>
                    </a-form-model>
                </a-row>
            </div>
            <div class="drawer-footer" style="float: left">
                <a-button @click="checkConfirm" type="primary" :style="{ marginRight: '8px' }">
                    确认
                </a-button>
                <a-button @click="checkClose"> 关闭 </a-button>
            </div>
        </a-drawer>
        <addType :show="addShow" @addClose="addShow = false" @refresh="getApi"></addType>
    </div>
</template>

<script>
import {getPhontTypeList} from "@/api/operation/suggestion"
import {getPhoneList,addPhone, findPhone, updatePhone, delPhone,reminderInfo,updateReminder} from "@/api/operation/suggestion"
import { rules, columns } from "./depend/config";
import addType from "./depend/addType.vue";
export default {
    components: {
		addType,
	},
    data() {
        return {
            form: {
                name: '',
                tel: ''
            },
            addForm: {
                name: '',
                tel: '',
                type: undefined,
                status: undefined,
                weight: 0,
            },
            checkForm: {
                reminderInterval: undefined,
                reminderContent: ''
            },
            mode: 1, //1新增 2编辑
            activeId: undefined,
            activeName: 0,
            rules: rules,
            columns: columns,
            tableData: [],
            pagination: {
                current: 1,
                total: 0,
                pageSize: 10,
                showTotal: (total) => `共 ${total} 条`,
                showSizeChanger: true,
                showQuickJumper: true,
            },
            //详情
            detailShow: false,
            //检查
            checkShow: false,
            //批量删除
            selectedRowKeys: [],
            //类型抽屉
            addShow: false,
            typeList: []
        }
    },
    created() {
        this.getApi()
        this.getData()
    },
    methods: {
        getApi() {
            getPhontTypeList().then(res => {
                let data = res.data;
                this.typeList = data;
            })
        },
        getData() {
            let obj = Object.assign(this.form, {pageNum: this.pagination.current,size: this.pagination.pageSize})
            getPhoneList(obj).then(res => {
                this.tableData = res.data.rows;
                this.pagination.total = res.data.total
            })
        },
        reset() {
            this.form = {
                name: '',
                tel: ''
            },
            this.getData()
        },
        handlerChange() {
            this.pagination.current = val.current;
            this.pagination.pageSize = val.pageSize;
            this.getData()
        },
        detailClose() {
            this.addForm = {
                name: '',
                tel: '',
                type: undefined,
                status: undefined,
                weight: 0,
            }
            this.detailShow = false
        },
        checkClose() {
            this.checkForm = {
                reminderInterval: undefined,
                reminderContent: ''
            }
            this.checkShow = false
        },
        //添加电话
        addConfirm() {
            if(this.mode == 1) {
                addPhone(this.addForm).then(res => {
                    if(res.code === 200){
                        this.$message.success(res.msg);
                        this.detailClose()
                        this.getData()
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            } else {
                let obj = Object.assign(this.addForm, {id: this.activeId})
                updatePhone(obj).then(res => {
                    if(res.code === 200){
                        this.$message.success(res.msg);
                        this.detailClose()
                        this.getData()
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            }
        },
        //编辑
        edit(val) {
            this.mode = 2;
            findPhone({conveniencePhoneId: val.id}).then(res => {
                let data = res.data;
                this.addForm = {
                    name: data.name,
                    tel: data.tel,
                    type: data.type,
                    status: data.status,
                    weight: data.weight,
                },
                this.activeId = data.id
            })
            this.detailShow = true;
        },
        //检查
        check() {
            reminderInfo().then(res => {
                let data = res.data;
                this.checkForm = data;
                this.checkShow = true;
            })
        },
        checkConfirm() {
            let obj = Object.assign(this.checkForm, {id: this.activeId})
            updateReminder(obj).then(res => {
                if(res.code === 200){
                    this.$message.success(res.msg);
                    this.checkClose()
                    this.getData()
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        //删除
        del(val) {
            this.$confirm({
                    title: "是否删除该电话？",
                    icon:'delete',
                    onOk:async()=>{
                        let res = await delPhone({conveniencePhoneIds: [val.id]})
                        if(res.code === 200){
                            this.$message.success(res.msg);
                            this.getData()
                        } else {
                            this.$message.error(res.msg);
                        }
                    },
            })
        },
        //批量删除
        selectionChoosed(val) {
            this.selectedRowKeys = val
        },
        handleMenuClick(data) {
            if (data.key === "del") {
                this.$confirm({
				title: "是否删除选中的电话？",
				icon:'delete',
				onOk:async()=>{
                        let res = await delPhone({conveniencePhoneIds: this.selectedRowKeys})
                        if(res.code === 200){
                            this.$message.success(res.msg);
                            this.selectedRowKeys = [];
                            this.getData()
                        } else {
                            this.$message.error(res.msg);
                        }
                    },
                })
			}
        },
        //新增电话类型
        addType() {
            this.addShow = true
        }
    },
    computed: {
		hasSelected() {
			return this.selectedRowKeys.length > 0;
		},
	},
}
</script>

<style lang="less">
.drawer-content {
    padding: 16px;
}
</style>