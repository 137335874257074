export const columns = [
	{
		title: "名称",
		width: "12%",
		dataIndex: "name",
	},
	{
		title: "联系方式",
		width: "12%",
		dataIndex: "tel",
	},
	{
		title: "类型",
		width: "12%",
		dataIndex: "typeName",
	},
	{
		title: "状态",
		width: "12%",
		dataIndex: "status",
        customRender: function (status) {
            switch (status) { 
                case 1: return '正常'
                case 2: return '有误'
				case 3: return '空号'
                case 4: return '搬家'
            }
        }
	},
    {
		title: "下次检查时间",
		width: "16%",
		dataIndex: "nextControlDate",
	},
	{
		title: "APP端排序权重",
		width: "12%",
		dataIndex: "weight",
	},
	{
		title: "提醒内容",
		width: "12%",
		dataIndex: "remindContent",
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "180",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]

export const rules = {
	name: [{ required: true, message: "请输入名称", trigger: "blur" }],
	tel: [
		{ required: true, message: "请输入手机号", trigger: "blur" },
		{ min: 11, max: 11, message: "手机号只能为11位", trigger: "blur" },
	],
	type: [{ required: true, message: "请选择类型", trigger: "change" }],
	status: [{ required: true, message: "请选择状态", trigger: "change" }],
	weight: [{ required: true, message: "请输入权重", trigger: "blur" }],
}