import httpService from "@/request"

// 查询所有便民电话信息
export function getPhoneList(params) {
	return httpService({
		url: `/user/conveniencePhone/list`,
		method: 'get',
		params: params,
	})
}

// 添加便民电话信息
export function addPhone(params) {
	return httpService({
		url: `/user/conveniencePhone/insert`,
		method: 'post',
		data: params,
	})
}

// 根据便民电话主键id查询便民电话信息
export function findPhone(params) {
	return httpService({
		url: `/user/conveniencePhone/findById`,
		method: 'get',
		params: params,
	})
}

// 修改便民电话信息
export function updatePhone(params) {
	return httpService({
		url: `/user/conveniencePhone/update`,
		method: 'post',
		data: params,
	})
}

// 定时检查信息
export function reminderInfo(params) {
	return httpService({
		url: `/user/conveniencePhone/reminderInfo`,
		method: 'get',
		params: params,
	})
}

// 更新定时检查信息
export function updateReminder(params) {
	return httpService({
		url: `/user/conveniencePhone/updateReminder`,
		method: 'post',
		data: params,
	})
}

// 批量删除便民电话
export function delPhone(params) {
	return httpService({
		url: `/user/conveniencePhone/delete`,
		method: 'post',
		data: params,
	})
}

// 查询所有的便民电话类型
export function getPhontTypeList(params) {
	return httpService({
		url: `/user/conveniencePhoneType/list`,
		method: 'get',
		params: params,
	})
}

// 添加便民电话类型
export function addPhontType(params) {
	return httpService({
		url: `/user/conveniencePhoneType/insert`,
		method: 'post',
		data: params,
	})
}

// 修改便民电话类型
export function updatePhontType(params) {
	return httpService({
		url: `/user/conveniencePhoneType/update`,
		method: 'post',
		data: params,
	})
}

// 删除便民电话类型
export function delPhontType(params) {
	return httpService({
		url: `/user/conveniencePhoneType/delete`,
		method: 'get',
		params: params,
	})
}